<template>
  <full-view title="About">Hello</full-view>
</template>

<script>
import FullView from "@/common/layouts/FullView";

export default {
  name: "About",
  components: { FullView },
};
</script>
